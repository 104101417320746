function recordStatsCtrl(){
};

module.exports = {
  template: require('./record-stats.html'),
  controller: recordStatsCtrl,
  bindings: {
    records: '='
  }
};
